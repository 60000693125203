import { useState, useEffect } from 'react';

import { LegacyCard, Page, ResourceItem, ResourceList, Text, EmptyState } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { restApi } from '../providers/restApi';
import { useHistory } from 'react-router-dom';

const FacebookEventRow = ({ pageId, eventId }) => {

  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    if(pageId && eventId) {
      setIsLoadingEvent(true);
      restApi.get(`/facebook/pages/${pageId}/events/${eventId}`)
      .then((response) => {
        setEvent(response);
        setIsLoadingEvent(false);
      })
    }
  }, [eventId, pageId]);

  if(isLoadingEvent) {
    return(
      <Text>Loading...</Text>
    )
  }

  return(
    <>
      <Text>{event.name}</Text>
      <Text>Datum: {event.start_time}</Text>
    </>
  )

}

const FacebookPage = () => {

  const { pageId } = useParams();
  const history = useHistory();

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);
  
  useEffect(() => {
    if(pageId) {
      setIsLoadingEvents(true);
      restApi.get(`/facebook/pages/${pageId}/events`)
      .then((response) => {
        setEvents(response.data);
        setIsLoadingEvents(false);
      })
    }
  }, [pageId]);

  const goToPreviousUrl = () => {
    history.goBack()
  };

  const gotoEvent = (eventId) => {
    history.push(`/facebook/${pageId}/${eventId}`)
  }

  const filteredEvents = events.filter(e => e.status_type === 'created_event')

  let emptyStateMarkup = filteredEvents.length === 0 ? (
    <EmptyState
      heading={`Inga evenemang att importera. Skapa dessa på din facebooksida för att sedan importera dem hit.`}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
    </EmptyState>
  ) : null;

  return(
   <Page
      backAction={{content: 'Dashboard', onAction : goToPreviousUrl }}
    >
    <LegacyCard 
      title="Evenemang"
    > 
      <ResourceList
        loading={isLoadingEvents}
        items={filteredEvents}
        emptyState={emptyStateMarkup}
        renderItem={(item) => {
          const { id } = item;
          const eventId = id.split('_')[1];
          return (
            <ResourceItem
              id={id}
              accessibilityLabel={`View details for ${id}`}
              onClick={() => { gotoEvent(eventId) }}
            >
              <FacebookEventRow eventId={eventId} pageId={pageId} />
            </ResourceItem>
          );
        }}
      />
    </LegacyCard>
   </Page> 
  )
}

export { FacebookPage }