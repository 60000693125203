import React, { useState, useContext, useEffect, useCallback } from "react"

import { Modal, Select, Toast } from "@shopify/polaris"

import { AuthContext } from '../../providers/AuthContext';
import { restApi } from '../../providers/restApi';

import { useHistory } from 'react-router-dom';

const ImportEventModal = ({visible, close, eventId, pageId}) => {

  const history = useHistory();

  const [isImportingEvent, setIsImportingEvent] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [toastActive, setToastActive] = useState(false);

  const { state: authState } = useContext(AuthContext);
  const { user } = authState;

  const toggleToastActive = useCallback(() => setToastActive((active) => !active), []);

  useEffect(() => {
    setAvailableOrganisations(user.organisations?.map((organisation) => {
      return {
        label: organisation.name,
        value: organisation.id
      }
    }) || []);
    const subscribed_page = user.subscribed_to_facebook_pages?.find((page) => page.pageId === pageId);
    if(subscribed_page) {
      setSelectedOrganisation(subscribed_page.organisationId);
    } else {
      if(user.organisations?.length > 0) {
        setSelectedOrganisation(user.organisations[0].id);
      }
    }
  }, [user.organisations, pageId, user.subscribed_to_facebook_pages]);
  
  const handleOrganisationChange = (value) => {
    setSelectedOrganisation(value);
  }

  const waitForESPropagation = (eventId, callback) => {
    setTimeout(() => {
      restApi.get(`/events/${eventId}`)
      .then((response) => {
        if(response.id === eventId) {
          callback();
        } else {
          waitForESPropagation(eventId, callback);
        }
      });
    }, 2000);
  }

  const gotoEvent = (eventId) => {
    history.push(`/events/${eventId}`)
  }

  const didClickImportEvent = () => {
    setIsImportingEvent(true);
    restApi.post(`/facebook/pages/${pageId}/events/${eventId}/import`, { organisationId: selectedOrganisation })      
      .catch((error) => {
        console.log(error);
      })
      .then((event) => {
        toggleToastActive();
        setIsImportingEvent(false);
        if(event?.id) {
          waitForESPropagation(event.id, () => {
            gotoEvent(event.id);
          });
        }
        close();
      })
  }

  const toastMarkup = toastActive ? (<Toast content="Evenemang importerat" onDismiss={toggleToastActive} />) : null;

  return(
    <>
      {toastMarkup}
      <Modal
        open={visible}
        onClose={close}
        title="Importera evenemang"
        primaryAction={{
          content: 'Importera',
          disabled: !selectedOrganisation || isImportingEvent,
          loading: isImportingEvent,
          onAction: didClickImportEvent,
        }}
        secondaryActions={[
          {
            content: 'Avbryt',
            disabled: isImportingEvent,
            onAction: close,
          },
        ]}
      >
        <Modal.Section>
          <Select
            label="Organisation som ska äga evenemanget"
            options={availableOrganisations}
            onChange={handleOrganisationChange}
            value={selectedOrganisation}
          />
        </Modal.Section>
      </Modal>
    </>
  )

}

export { ImportEventModal }