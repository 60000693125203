import React, { useState, useContext, useCallback } from "react"

import { Modal, Text, Toast } from "@shopify/polaris"

import { AuthContext } from '../../providers/AuthContext';
import { restApi } from '../../providers/restApi';

const DisconnectPageModal = ({visible, close, page}) => {

  const [isDisconnectingPage, setIsDisconnectingPage] = useState(false);
  const [toastActive, setToastActive] = useState(false);

  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const { user } = authState;

  const toggleToastActive = useCallback(() => setToastActive((active) => !active), []);

  const didClickDisconnectPage = () => {
    setIsDisconnectingPage(true);
    restApi.post('/facebook/disconnect', { pageId: page.id })
      .then(() => {
        toggleToastActive();
        authDispatch({ type: 'setSubscribedFacebookPages', value: user.subscribed_to_facebook_pages.filter(p => p.pageId !== page.id) });
      })
      .catch((error) => {
        console.log(error);
      }).then(() => {
        setIsDisconnectingPage(false);
        close();
      });
  }

  const toastMarkup = toastActive ? (<Toast content="Sida bortkopplad" onDismiss={toggleToastActive} />) : null;

  return(
    <>
      {toastMarkup}
      <Modal
          open={visible}
          onClose={close}
          title="Koppla bort sida"
          primaryAction={{
            content: 'Koppla bort',
            loading: isDisconnectingPage,
            onAction: didClickDisconnectPage,
          }}
          secondaryActions={[
            {
              content: 'Avbryt',
              disabled: isDisconnectingPage,
              onAction: close,
            },
          ]}
        >
        <Modal.Section>
          <Text>
            Om du kopplar bort sidan kommer vi inte längre att kunna hämta evenemang från sidan automatiskt.
          </Text>
        </Modal.Section>
      </Modal>
    </>
  );

}

export { DisconnectPageModal }