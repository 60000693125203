import { LegacyCard, Page } from "@shopify/polaris";

export const Privacy = () => {
  return(
    <Page>
      <LegacyCard>
        <LegacyCard.Section>
          <div class="policy" style={{padding: 40}}>
            <h1>Integritetspolicy</h1>
            <h3>Behandling av personuppgifter på Candy Banana AB</h3>
            <h5>Maj 2023</h5>
            <p>
              För Candy Banana AB är personlig integritet viktigt. 
              Vi eftersträvar en hög nivå av dataskydd. 
              I denna policy förklarar vi hur vi samlar in och använder personuppgifter. 
              Vi beskriver också dina rättigheter och hur du kan göra dem gällande. 
            </p>
            <p>
              Du är alltid välkommen att kontakta oss om du har frågor om hur vi behandlar dina personuppgifter. 
              Kontaktuppgifter står sist i denna text. 
            </p>
            <h4>Vad är en personuppgift och vad är en behandling av personuppgifter? </h4>
            <p>
              Personuppgifter är alla uppgifter om en levande fysisk person som direkt eller indirekt kan kopplas till den personen. 
              Det handlar inte bara om namn och personnummer utan även om till exempel bilder och e-postadresser. 
            </p>
            <p>
              Behandling av personuppgifter är allt som sker med personuppgifterna i IT-systemen,
               oavsett om det handlar om mobila enheter eller datorer. 
               Det handlar om till exempel insamling, registrering, strukturering, lagring, bearbetning och överföring. 
               I vissa fall kan även manuella register omfattas. 
              </p>
            <h4>Personuppgiftsansvarig</h4>
            <p>För de behandlingar som sker inom Candy Banana AB:s verksamhet är Candy Banana AB personuppgiftsansvarig. 
              ( 556999-5813 Pinnarviken 319). </p>
            <h4>Vilka personuppgifter samlar vi in om dig och varför?</h4>
            <p>
              Vi behandlar i huvudsak din e-postadress och ditt namn.
            </p>
            <p>
              Vi behandlar dina personuppgifter för att kunna tillhandahålla våra tjänster och produkter till dig.
            </p>
            <h4>Personuppgiftsbiträden</h4>
            <p>
              I en del situationer är det nödvändigt för oss att anlita andra parter. 
              Till exempel olika IT-leverantörer (Facebook & Google). 
              De är då personuppgiftsbiträden till oss. 
              Vi kontrollerar personuppgiftsbiträden för att säkerställa att de garanterar säkerheten och sekretessen för personuppgifterna.
              När personuppgiftsbiträden anlitas sker det bara för de ändamål som är förenliga med de ändamål vi själva har för behandlingen. 
            </p>
            <h4>
              Aktörer som är självständigt personuppgiftsansvariga 
            </h4>
            <p>
              Vi delar inte dina personuppgifter med någon annan.
              </p>
            <h4>
              Hur länge sparar vi dina personuppgifter? 
            </h4>
            <p>
              Vi sparar aldrig dina personuppgifter längre än vad som är nödvändigt för respektive ändamål.
            </p>
            <h4>
              Vad är dina rättigheter som registrerad? 
            </h4>
            <p>
              Som registrerad har du enligt gällande lagstiftning ett antal rättigheter. 
              Du har rätt till att få ett utdrag som visar vilka personuppgifter vi har registrerade om dig. 
              Du kan begära rättelse av felaktiga uppgifter och i vissa fall radering.
            </p>
            <h4>
              Kontakta oss vid frågor om hur vi behandlar personuppgifter.
            </h4>
            <p>
              Om du har frågor om hur vi behandlar personuppgifter kontakta n.n. som är ansvarig för personuppgiftsfrågor.
              Vi kan komma att göra ändringar i vår integritetspolicy. Den senaste versionen av integritetspolicyn finns alltid här på webbplatsen.
            </p>
            <h5>
              Kontaktuppgifter
            </h5>
            <p>
              Candy Banana AB<br />
              Org.nr:  556999-5813<br />
              Adress: Pinnarviken 319, 826 91 Söderhamn<br />
              E-post: micke@candybanana.se
            </p>
          </div>
        </LegacyCard.Section>
      </LegacyCard>
    </Page>
  );
}