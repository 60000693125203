/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_identity_pool_id": "eu-north-1:46b0b197-5d6e-4920-b0e8-b49dc1ecd52a",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_KjjJRgZ56",
    "aws_user_pools_web_client_id": "2uf7t64744gojul6v20nk0lbjg",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "verkstadernaadmin-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-north-1",
    "aws_content_delivery_url": "https://dryhl3erkmowr.cloudfront.net"
};


export default awsmobile;
