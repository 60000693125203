import { useState, useEffect } from 'react';

import { LegacyCard, Page, Spinner } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import { restApi } from '../providers/restApi';
import { useHistory } from 'react-router-dom';
import { ImportEventModal } from '../components/facebook/ImportEventModal';

const FacebookEvent = () => {

  const { eventId, pageId } = useParams();
  const history = useHistory();

  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [event, setEvent] = useState([]);
  const [importModalVisible, setImportModalVisible] = useState(false);
  
  useEffect(() => {
    if(eventId && pageId) {
      setIsLoadingEvent(true);
      restApi.get(`/facebook/pages/${pageId}/events/${eventId}`)
      .then((response) => {
        setEvent(response);
        setIsLoadingEvent(false);
      })
    }
  }, [eventId, pageId]);

  const importEvent = () => {
    setImportModalVisible(true);
  }
  
  const goToPreviousUrl = () => {
    history.goBack()
  };

  return(
   <Page
      backAction={{content: 'Dashboard', onAction : goToPreviousUrl }}
      primaryAction={{content: 'Importera', onAction: importEvent }}
    >
    <LegacyCard 
      title={event?.name || "Hämtar evenemang"}
    > 
      {isLoadingEvent && (
        <LegacyCard.Section>
          <Spinner />
        </LegacyCard.Section> 
      )}
      {!isLoadingEvent && (
        <>
          <LegacyCard.Section>
            <p>Börjar: {event.start_time}</p>
            <p>Slutar: {event.end_time || 'n/a'}</p>
            <p>Place: {event.place?.name || 'n/a'}</p>
            <p>Long lat: {event.place?.location?.latitude || '-'}, {event.place?.location?.longitude || '-'}</p>
          </LegacyCard.Section>
          <LegacyCard.Section>
              <div style={{ overflow: 'auto'}}>
                <pre>{event.description}</pre>
              </div>
          </LegacyCard.Section>
        </>
      )}
    </LegacyCard>
    <ImportEventModal eventId={eventId} pageId={pageId} visible={importModalVisible} close={() => setImportModalVisible(false)} />
   </Page> 
  )
}

export { FacebookEvent }