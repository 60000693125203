import { useEffect, useState, useContext } from 'react';

import { LegacyCard, Page, ResourceItem, ResourceList, Text, Modal, Avatar, Spinner, EmptyState } from '@shopify/polaris';
import { AuthContext } from '../providers/AuthContext';

import { restApi } from '../providers/restApi';

import { useHistory } from 'react-router-dom';
import { ConnectPageModal } from '../components/facebook/ConnectPageModal';
import { DisconnectPageModal } from '../components/facebook/DisconnectPageModal';

const Home = () => {

  const history = useHistory();

  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);

  const [facebookPages, setFacebookPages] = useState([]);
  const [isLoadingFacebookAccounts, setIsLoadingFacebookAccounts] = useState(false);
  const [disconnectFacebookModalVisible, setDisconnectFacebookModalVisible] = useState(false);
  const [connectPageModalVisible, setConnectPageModalVisible] = useState(false);
  const [disconnectPageModalVisible, setDisconnectPageModalVisible] = useState(false);
  const [pageToConnect, setPageToConnect] = useState(null);
  const [waitingForESPropagation, setWaitingForESPropagation] = useState(false);
  const [isDisconnectingFacebook, setIsDisconnectingFacebook] = useState(false);

  const { user } = authState;

  const fbLogin = async () => {
    const authResponse = await facebookLogin();
    // handle the response
    if(authResponse) {
      const { accessToken: access_token } = authResponse;
      restApi.post('/facebook/get-long-lived-token', { access_token })
      .then((tokenResponse) => {
        const { access_token } = tokenResponse;
        restApi.post('/facebook/fb-token', { access_token })
        .then((response) => {
          waitForESPropagation();
        });
      });        
    }
  }

  const facebookLogin = () => {
    return new Promise((resolve) => {
      window.FB.login(function({ authResponse }) {
        resolve(authResponse);
      }, {scope: 'public_profile,pages_show_list,pages_manage_metadata,pages_read_engagement'})
    });
  }

  const waitForESPropagation = () => {
    setWaitingForESPropagation(true);
    setTimeout(() => {
      restApi.get('/admin/me')
      .then((response) => {
        const { fb_access_token } = response;
        if(fb_access_token) {
          authDispatch({ type: 'setIsFacebookAuthenticated', value: true });
          authDispatch({ type: 'setFacebookAccessToken', value: fb_access_token });
          setWaitingForESPropagation(false);
        } else {
          waitForESPropagation();
        }
      });
    }, 2000);
  }

  useEffect(() => {
    if(authState.isFacebookAuthenticated) {
      setIsLoadingFacebookAccounts(true);
      restApi
      .get('/facebook/pages')
      .then((response) => {
        setFacebookPages(response.data);
        setIsLoadingFacebookAccounts(false);
      });
    }
  }, [authState.isFacebookAuthenticated]);

  const facebookPageIsConnected = (page) => {
    const subscribed_to_facebook_pages = user.subscribed_to_facebook_pages || [];
    return(subscribed_to_facebook_pages.find((subscribedPage) => subscribedPage.pageId === page.id) ? true : false);
  }

  const disconnectFacebook = () => {
    setIsDisconnectingFacebook(true);
    restApi.delete('/facebook/fb-token')
    .then((response) => {
      console.log('Delete Facebook Token Response', response);
      window.FB.getLoginStatus(function(response) {
        if(response.status === 'connected') {
          window.FB.api('/me/permissions', 'delete', function(response) {        
            window.FB.logout(function(response) {
              console.log('FB Logout', response);
              authDispatch({ type: 'setIsFacebookAuthenticated', value: false });
              authDispatch({ type: 'setFacebookAccessToken', value: null });
              setIsDisconnectingFacebook(false);
              setDisconnectFacebookModalVisible(false);
            });
          });
        } else {
          alert('not connected');
        }
      });
    }).catch((error) => {
      console.log('Delete Facebook Token Error', error);
      setIsDisconnectingFacebook(false);
    });
  }

  const toggleConnectFacebookPage = (page) => {
    setPageToConnect(page);
    if(facebookPageIsConnected(page)) {
      setDisconnectPageModalVisible(true);
    } else {
      setConnectPageModalVisible(true);
    }
  }

  const manageFacebookPage = (page) => {
    if(facebookPageIsConnected(page)) {
      history.push(`/facebook/${page.id}`);
    }
  }

  return(
    <Page>
      {/* connect modal */}
      <ConnectPageModal 
        visible={connectPageModalVisible} 
        close={e => setConnectPageModalVisible(false)} 
        page={pageToConnect}
      />
      {/* connect modal */}
      <DisconnectPageModal
        visible={disconnectPageModalVisible}
        close={e => setDisconnectPageModalVisible(false)}
        page={pageToConnect}
      />
      {/* disconnect facebook modal */}
      <Modal
        open={disconnectFacebookModalVisible}
        title="Koppla bort Facebook"
        onClose={() => setDisconnectFacebookModalVisible(false)}
        primaryAction={{
          content: 'Koppla bort',
          loading: isDisconnectingFacebook,
          onAction: () => disconnectFacebook(),
        }}
        secondaryActions={[
          {
            content: 'Avbryt',
            onAction: () => setDisconnectFacebookModalVisible(false),
          },
        ]}
      >
        <Modal.Section>
          <Text>
            Om du kopplar bort Facebook kommer vi inte längre att kunna hämta evenemang från Facebook automatiskt.
          </Text>
        </Modal.Section>
      </Modal>
      {!authState.isFacebookAuthenticated && authState.isFacebookAuthenticated !== null && (
      <LegacyCard        
        title="Koppla ihop med Facebook"
        primaryFooterAction={{
          content: 'Koppla ihop med Facebook',
          loading: waitingForESPropagation,
          onAction: () => { fbLogin() }
        }}
      >
        <LegacyCard.Section>
          <Text>
            Koppla ihop med Facebook för att automatiskt hämta evenemang och skapa en kopia av dessa på SHMN.Nu.
          </Text>
        </LegacyCard.Section>
      </LegacyCard>
      )}
      {authState.isFacebookAuthenticated && authState.isFacebookAuthenticated !== null && (
      <LegacyCard        
        title="Koppla bort Facebook"
        primaryFooterAction={{
          content: 'Koppla bort Facebook',
          onAction: () => setDisconnectFacebookModalVisible(true)
        }}
      >
        <LegacyCard.Section>
          <Text>
            Koppla bort om du inte längre till använda tjänsten. SHMN.Nu kommer inte längre att kunna hämta evenemang från Facebook automatiskt.
          </Text>
        </LegacyCard.Section>
      </LegacyCard>
      )}
      {waitingForESPropagation && (
        <LegacyCard>
          <LegacyCard.Section>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Spinner size="large" color="teal" />
            </div>
          </LegacyCard.Section> 
        </LegacyCard>
      )}
      {authState.isFacebookAuthenticated && (
        <LegacyCard>
          <ResourceList
            emptyState={(
              <EmptyState
                heading={`Inga sidor att hantera. Skapa dessa på ditt facebook kontot för att sedan koppla dem här.`}
                image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
              >
              </EmptyState>
            )}
            loading={isLoadingFacebookAccounts}
            items={facebookPages}
            renderItem={(page) => {
              const media = <Avatar customer size="medium" name={"no name"} />;
              return(
                <ResourceItem
                  shortcutActions={[{ 
                    content: facebookPageIsConnected(page) ? 'Koppla bort' : 'Koppla', 
                    onAction: () => { toggleConnectFacebookPage(page) }
                  }]}
                  persistActions
                  id={page.id}
                  media={media}
                  onClick={() => { manageFacebookPage(page) } }
                  accessibilityLabel={`Koppla ihop ${page.name}`}
                >
                  <Text>
                    {page.name}
                  </Text>
                  <div>
                      {facebookPageIsConnected(page) && (
                        <Text color='success'>
                          <span>Kopplad</span>
                        </Text>
                      )}
                       {!facebookPageIsConnected(page) && (
                        <Text color='warning'>
                          <span>Ej Kopplad</span>
                        </Text>
                      )}
                  </div>
                </ResourceItem>
              )
            }}
          >
          </ResourceList>
        </LegacyCard>
      )}
    </Page>
  );
}


export { Home }